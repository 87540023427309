import products, {initialState as productsState} from './products';
import rubrics, {initialState as rubricsState} from './rubrics';
import railsContext, {initialState as railsContextState} from './railsContext';
import application, {initialState as applicationState} from './application';
import cart, {initialState as cartState} from './cart';
import brands, {initialState as brandsState} from './brands';
import search, {initialState as searchState} from './search';
import userRequests, {initialState as userRequestsState} from './userRequests';
import articles, {initialState as articlesState} from './articles';
import auth, {initialState as authState} from './auth';
import {pendingTasksReducer} from 'react-redux-spinner';

export default {
  productsState: products,
  brandsState: brands,
  searchState: search,
  railsContext: railsContext,
  pendingTasks: pendingTasksReducer,
  rubricsState: rubrics,
  cartState: cart,
  applicationState: application,
  userRequestsState: userRequests,
  articlesState: articles,
  authState: auth,
};

export const initialStates = {
  productsState,
  railsContext: railsContextState,
  rubricsState,
  cartState,
  applicationState,
  brandsState,
  searchState,
  userRequestsState,
  articlesState,
  authState
};
