import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const setAuthOpen = (open) => ({
  type: ACTIONS.SET_AUTH_OPEN,
  payload: {open}
});

export const setOtpOpen = (open) => ({
  type: ACTIONS.SET_OTP_OPEN,
  payload: {open}
});

export const auth = (email) => ({
  type: ACTIONS.AUTH,
  payload: {email}
});

export const authSuccess = (data) => ({
  type: ACTIONS.AUTH_SUCCESS,
  payload: {data}
});
export const authFailed = (data) => ({
  type: ACTIONS.AUTH_FAILED,
});

export const resetAuthErrors = () => ({
  type: ACTIONS.RESET_AUTH_ERRORS
});

export const otpAuth = (otp, email, token) => ({
  type: ACTIONS.OTP_AUTH,
  payload: {otp, email, token}
});

export const otpAuthSuccess = () => ({
  type: ACTIONS.OTP_AUTH_SUCCESS
});
export const otpAuthFailed = () => ({
  type: ACTIONS.OTP_AUTH_FAILED
});

export const resetOtp = () => ({
  type: ACTIONS.RESET_OTP_FORM
});
export const resetOtpData = () => ({
  type: ACTIONS.RESET_OTP_DATA
});
export const resetOtpError = () => ({
  type: ACTIONS.RESET_OTP_ERROR
});
