import React from 'react';

const CheckedSmallIcon = ({width = 20, height = 20, ...props}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.3347 6.33422C16.4974 6.49694 16.4974 6.76076 16.3347 6.92348L8.24386 15.0143C8.08114 15.1771 7.81732 15.1771 7.6546 15.0143L2.83298 10.1927C2.67026 10.03 2.67026 9.76617 2.83298 9.60345L3.83471 8.60172C3.99743 8.439 4.26125 8.439 4.42397 8.60172L7.6546 11.8324C7.81732 11.9951 8.08114 11.9951 8.24386 11.8324L14.7437 5.33249C14.9064 5.16977 15.1703 5.16977 15.333 5.33249L16.3347 6.33422Z" fill="#1536A1" />
    </svg>

  );
}

export default CheckedSmallIcon;
