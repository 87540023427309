import React from 'react';

const EmailIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM8.33333 14.75C8.33333 13.0471 9.71379 11.6667 11.4167 11.6667H28.5833C30.2862 11.6667 31.6667 13.0471 31.6667 14.75V25.25C31.6667 26.9529 30.2862 28.3333 28.5833 28.3333H11.4167C9.71379 28.3333 8.33333 26.9529 8.33333 25.25V14.75ZM10.6756 14.108C11.1682 13.5607 12.0113 13.5163 12.5586 14.0089L17.5471 18.4986C18.9416 19.7536 21.0584 19.7536 22.4529 18.4986L27.4414 14.0089C27.9887 13.5163 28.8318 13.5607 29.3244 14.108C29.817 14.6554 29.7726 15.4984 29.2253 15.9911L24.2368 20.4807C21.8282 22.6485 18.1718 22.6485 15.7632 20.4807L10.7747 15.9911C10.2274 15.4984 10.183 14.6554 10.6756 14.108Z" fill="url(#paint0_linear_3211_15077)"/>
      <defs>
        <linearGradient id="paint0_linear_3211_15077" x1="40" y1="20" x2="1.49943e-08" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#426BEF"/>
          <stop offset="1" stopColor="#244CCB"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EmailIcon;
