import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as authActions from '../../actions/authActionsCreators';
import CloseIcon from '../Icons/Close';
import {Drawer, Modal} from 'antd';
import EmailForm from './EmailForm';
import {otpAuth, resetOtpError, setOtpOpen} from '../../actions/authActionsCreators';
import OtpForm from './OtpForm';
import {parseTime} from '../../tools/helpers';

const Auth = (props) => {
  const
    {is_mobile} = props.applicationState,
    [email, setEmail] = useState(''),
    [otp, setOtp] = useState(''),
    [token, setToken] = useState(''),
    handleEmailChanged = (event) => {
      setEmail(event.target.value);
      if (props.authState.authError) {
        props.authActions.resetAuthErrors();
      }
    },
    handleOtpChanged = (otp) => {
      setOtp(otp);

      if (props.authState.otpError) {
        props.authActions.resetOtpError();
      }

      if (otp.length === 6 && token && email) {
        props.authActions.otpAuth(otp, email, token);
      }
    },
    handleEmailSubmit = () => {
      props.authActions.auth(email);
    },
    handleOtpSubmit = () => {
    },
    handleClose = () => {
      props.authActions.setAuthOpen(false);
    },
    handleOtpClose = () => {
      props.authActions.setOtpOpen(false);
      props.authActions.resetOtp();
      setOtp('');
    };

  useEffect(() => {
    const newToken = props.authState.authData?.token
    if (!newToken || newToken === token) { return; }
    props.authActions.resetOtpData();
    setToken(newToken);
  }, [token, setToken, props.authState.authData?.token]);


  return <>
    {
      is_mobile ?
        <Drawer
          className='select-drawer'
          getContainer='body'
          placement='bottom'
          onClose={handleClose}
          title='Войти или создать профиль'
          closeIcon={<CloseIcon />}
          open={props.authState.open}
        >
          <EmailForm
            email={email}
            handleChange={handleEmailChanged}
            handleSubmit={handleEmailSubmit}
          />
        </Drawer>
        :
        <Modal
          open={props.authState.open}
          onCancel={handleClose}
          width={500}
          title='Войти или создать профиль'
          closeIcon={<CloseIcon height={20} width={20} />}
          footer={null}
        >
          <EmailForm
            email={email}
            handleChange={handleEmailChanged}
            handleSubmit={handleEmailSubmit}
          />
        </Modal>
    }
    {
      is_mobile ?
        <Drawer
          className='select-drawer'
          getContainer='body'
          placement='bottom'
          onClose={handleOtpClose}
          title='Введите код'
          closeIcon={<CloseIcon />}
          open={props.authState.otpOpen}
        >
          {
            props.authState.otpOpen ?
              <OtpForm
                otp={otp}
                onClose={handleOtpClose}
                handleChange={handleOtpChanged}
                handleSubmit={handleOtpSubmit}
              />
              : null
          }
        </Drawer>
      :
        <Modal
          open={props.authState.otpOpen}
          onCancel={handleOtpClose}
          width={390}
          title='Введите код'
          closeIcon={<CloseIcon height={20} width={20} />}
          footer={null}
        >
          {
            props.authState.otpOpen ?
              <OtpForm
                otp={otp}
                onClose={handleOtpClose}
                handleChange={handleOtpChanged}
                handleSubmit={handleOtpSubmit}
              />
              : null
          }
        </Modal>
    }
  </>
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      authActions: bindActionCreators(authActions, dispatch),
    };
  },
  mapStateToProps = ({applicationState, authState}) => {
    return {
      applicationState,
      authState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(Auth);
