import React from 'react';

const StarIcon = ({height= 16, width= 16, outlined = false}) => {
  return outlined ? (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3205_14476)">
          <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM16.6787 12.3098L16.2963 12.9956C15.8765 13.7489 15.6665 14.1256 15.3392 14.3741C15.0117 14.6226 14.604 14.7149 13.7885 14.8994L13.0461 15.0673C10.1765 15.7166 8.74169 16.0413 8.40033 17.1391C8.05898 18.2366 9.03698 19.3803 10.993 21.6678L10.9935 21.6683L11.4996 22.26L11.4997 22.2601C12.0555 22.9101 12.3335 23.2352 12.4585 23.6374C12.5835 24.0394 12.5415 24.4732 12.4575 25.3405L12.381 26.1301C12.0852 29.1823 11.9373 30.7086 12.831 31.3869C13.7247 32.0654 15.0681 31.4468 17.7549 30.2097L18.45 29.8896L18.4535 29.8879C19.2149 29.5376 19.596 29.3622 20 29.3622C20.404 29.3622 20.7851 29.5376 21.5465 29.8879L21.55 29.8896L22.2451 30.2097L22.2455 30.2099C24.9321 31.4469 26.2753 32.0654 27.1689 31.3869C28.0626 30.7086 27.9149 29.1823 27.619 26.1301L27.5425 25.3405C27.4585 24.4732 27.4165 24.0394 27.5416 23.6374C27.6666 23.2351 27.9445 22.9101 28.5003 22.26L29.0067 21.6683L29.0071 21.6678C30.9631 19.3803 31.941 18.2366 31.5997 17.1391C31.2583 16.0413 29.8236 15.7166 26.9538 15.0673L26.2116 14.8994L26.2114 14.8993C25.396 14.7149 24.9882 14.6226 24.6608 14.3741C24.3335 14.1256 24.1235 13.7489 23.7037 12.9956L23.3213 12.3098C21.8436 9.6588 21.1046 8.33333 20 8.33333C18.8954 8.33333 18.1564 9.65883 16.6787 12.3098Z" fill="url(#paint0_linear_3205_14476)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_3205_14476" x1="40" y1="20" x2="1.49943e-08" y2="20" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9900"/>
            <stop offset="1" stopColor="#FF4B55"/>
          </linearGradient>
          <clipPath id="clip0_3205_14476">
            <rect width="40" height="40" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg width={height} height={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.30623 4.79801L6.48105 4.48449C7.15657 3.27263 7.49439 2.66669 7.99934 2.66669C8.5043 2.66669 8.84212 3.27262 9.51764 4.48449L9.69246 4.79802C9.88436 5.14238 9.98036 5.31456 10.13 5.42818C10.2797 5.54179 10.4661 5.58396 10.8389 5.6683L11.1782 5.74509C12.4901 6.04191 13.146 6.19032 13.3021 6.69217C13.4581 7.19393 13.011 7.71681 12.1167 8.76268L11.8852 9.03318C11.6311 9.33036 11.5041 9.47894 11.4469 9.66283C11.3897 9.84662 11.4089 10.0449 11.4473 10.4414L11.4823 10.8024C11.6176 12.1977 11.6851 12.8954 11.2766 13.2054C10.868 13.5156 10.254 13.2329 9.02569 12.6673L8.70793 12.521C8.35881 12.3603 8.1843 12.2799 7.99934 12.2799C7.81438 12.2799 7.63988 12.3603 7.29076 12.521L6.973 12.6673C5.74477 13.2329 5.13064 13.5156 4.72209 13.2054C4.31355 12.8954 4.38115 12.1977 4.51636 10.8024L4.55134 10.4414C4.58976 10.0449 4.60897 9.84662 4.55181 9.66283C4.49464 9.47894 4.36757 9.33036 4.11344 9.03318L3.88207 8.76268C2.98774 7.71681 2.54059 7.19393 2.69664 6.69217C2.85269 6.19032 3.50862 6.0419 4.82044 5.74509L5.15982 5.6683C5.53261 5.58396 5.71899 5.54179 5.86868 5.42818C6.01833 5.31456 6.11433 5.14238 6.30623 4.79801Z" fill="url(#paint0_linear_2136_16289)"/>
        <defs>
          <linearGradient id="paint0_linear_2136_16289" x1="13.3327" y1="8.00002" x2="2.66602" y2="8.00002" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9900"/>
            <stop offset="1" stopColor="#FF4B55"/>
          </linearGradient>
        </defs>
      </svg>
    );
}

export default StarIcon;
