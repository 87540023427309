import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';

import * as applicationActions from '../../actions/applicationActionCreators';
import * as brandsActions from '../../actions/brandsActionCreators';
import {useMount} from 'react-use';
import Slider from '../../components/Slider';
import IconBlocks from '../../components/IconBlocks';
import ProductsList from '../../components/ProductsList';
import HomeObjectsSlider from '../../components/HomeObjectsSlider';
import BrandsPreviewList from '../../components/BrandsPreviewList';
import RubricsPreviewList from '../../components/RubricsPreviewList';
import ArticlesJournal from '../../components/ArticlesJournal';
import StocksPreviewList from '../../components/StocksPreviewList';
import TextSnippet from '../../components/TextSnippet';
import Button from '../../components/Button';
import constants from '../../constants';
import SubscriptionForm from '../../components/Forms/Subscription';
import SeoTags from '../../routes/SeoTags';

const HomePage = (props) => {
  const
    {is_mobile, current_city} = props.applicationState;

  useMount(() => {
    props.applicationActions.fetchHomePageContent();
    props.brandsActions.fetchPopularBrands();
  });

  return (
    <Layout>
      <SeoTags controller_action='root#index' />
      <div className='container'>
        <Slider slides={props.applicationState.homeSlidesTop} className='top' />

        <section className='page-content-section'>
          <IconBlocks blocks={props.applicationState.homeIconCards} />
        </section>

        {
          props.applicationState.homeRubrics.length ?
            <section className='page-content-section'>
              <h2>
                <span>Популярные категории</span>
                {
                  is_mobile ?
                    <Link to={constants.ROUTES.REGIONAL_CATALOGUE_PATH(current_city.slug)} className="all-records-link">Все</Link>
                    : <Button secondary to={constants.ROUTES.REGIONAL_CATALOGUE_PATH(current_city.slug)}>Все категории</Button>
                }

              </h2>
              <RubricsPreviewList rubrics={props.applicationState.homeRubrics} />
            </section>
            : null
        }

        <section className='page-content-section'>
          <h3>
            Акции
            {
              is_mobile ?
                <Link to={constants.ROUTES.REGIONAL_STOCKS_PATH(current_city.slug)} className="all-records-link">Все акции</Link>
                : <Button secondary to={constants.ROUTES.REGIONAL_STOCKS_PATH(current_city.slug)}>Все акции</Button>
            }

          </h3>
          <StocksPreviewList stocks={props.applicationState.homeStocks} />
        </section>

        <section className='page-content-section'>
          <h3>
            Хиты продаж
            {
              is_mobile ?
                <Link to={'/'} className="all-records-link">Все</Link>
                : <Button secondary to={'/'}>Смотреть все</Button>
            }
          </h3>
          <ProductsList products={props.applicationState.homeOffers} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
        </section>

        <section className='page-content-section'>
          <h3>
            {is_mobile ? 'Производители' : 'Популярные бренды'}
            {
              is_mobile ?
                null //<Link to={constants.ROUTES.REGIONAL_BRANDS_PATH(current_city.slug)} className="all-records-link">Все бренды</Link>
                : <Button secondary to={constants.ROUTES.REGIONAL_BRANDS_PATH(current_city.slug)}>Все бренды</Button>
            }
          </h3>
          <BrandsPreviewList brands={props.brandsState.popular_brands} />
        </section>

        <section className='page-content-section'>
          <h3>
            Наш журнал
            {
              is_mobile ?
                <Link to={constants.ROUTES.REGIONAL_ARTICLES_PATH(current_city.slug)} className="all-records-link">Все</Link>
                : <Button secondary to={constants.ROUTES.REGIONAL_ARTICLES_PATH(current_city.slug)}>Весь журнал</Button>
            }
          </h3>
          <ArticlesJournal articles={props.applicationState.homeArticles} />
        </section>

        <section className='page-content-section message-form-section'>
          {
            is_mobile ?
              <div className='message-form__wrapper'>
                <SubscriptionForm />
              </div>
              : <>
                <div className='message-form-section__col message-form-section__col_1'>
                  <Slider slides={props.applicationState.homeSlidesBottom} className='bottom' />
                </div>
                <div className='message-form-section__col message-form-section__col_2'>
                  <div className='message-form__wrapper'>
                    <SubscriptionForm />
                  </div>
                </div>
              </>
          }
        </section>

        {
          props.applicationState.homeText && !is_mobile ?
            <section className='page-content-section'>
              <TextSnippet text={props.applicationState.homeText}/>
            </section>
            : null
        }
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch),
      brandsActions: bindActionCreators(brandsActions, dispatch),
    };
  },

  mapStateToProps = ({rubricsState, railsContext, applicationState, brandsState, userRequestsState}) => {
    return {
      applicationState,
      rubricsState,
      railsContext,
      brandsState,
      userRequestsState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(HomePage));
