import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import * as applicationActions from '../../actions/applicationActionCreators';
import {useMount} from 'react-use';
import CityContacts from '../../components/CityContacts';
import './style.scss';

const ContactsPage = (props) => {
  useMount(() => {
    props.applicationActions.fetchCity(props.applicationState.current_city.id);
  });
  return (
    <Layout>
      <div className='container contacts-page'>
        <h1>Адреса в {props.applicationState.current_city.name_preposition}</h1>
        <CityContacts />
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch)
    };
  },

  mapStateToProps = ({applicationState, railsContext}) => {
    return {
      applicationState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(ContactsPage));
