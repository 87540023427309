import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Input} from 'antd';
import Button from '../../Button';
import './style.scss';
import CheckedSmallIcon from '../../Icons/CheckedSmall';
const EmailForm = (props) => {
  return <>
    <div className='auth-email-form'>
      <div className='auth-email-form__row'>
        <Input type='email' name='email' value={props.email} className={props.authState.authError ? 'error' : ''} placeholder='Введите электронную почту' onChange={props.handleChange} />
      </div>
      <div className='auth-email-form__row'>
        <Button primary disabled={!props.email?.length || props.authState.authError} className='w-full l' onClick={props.handleSubmit}>Получить код</Button>
      </div>
      <div className='auth-email-form__row'>
        <div className='auth-email-form__submit-note'>
          <CheckedSmallIcon />
          <p className='auth-email-form__submit-note__col'>
            <span>Соглашаюсь</span> с условиями Политики в отношении обработки персональных данных
          </p>
        </div>
      </div>
    </div>
  </>
}

const
  mapDispatchToProps = (dispatch) => {
    return {
    };
  },
  mapStateToProps = ({applicationState, authState}) => {
    return {
      applicationState,
      authState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(EmailForm);
