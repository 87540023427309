import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import OtpInput from 'react-otp-input';
import {parseTime, secondsToFormat} from '../../../tools/helpers';
import Button from '../../Button';
import './style.scss';
import * as authActions from '../../../actions/authActionsCreators';
import {useMount} from 'react-use';

const OtpForm = (props) => {
  const
    [time, setTime] = useState(parseTime(props.authState.authData?.otp_time_left)),
    handleReset = () => {
      props.onClose && props.onClose();
      props.authActions.resetOtp();
    };

  useEffect(() => {
    if (!time) { return; }
    if (time <= 0) {
      setTime(null);
      return;
    }
    setTimeout(() => setTime(time - 1), 1000);
  }, [time, setTime]);

  useEffect(() => {
    if (props.authState.authData?.otp_time_left !== '01:00') { return; }
    const nextTime = parseTime(props.authState.authData?.otp_time_left);
    props.authActions.resetOtpData();
    setTime(nextTime);
  }, [time, setTime, props.authState.authData?.otp_time_left]);

  return <>
    <div className='auth-email-form'>
      <div className='auth-email-form__row'>
        <div className={`otp-input ${props.authState.otpError ? 'has-error' : ''} ${props.authState.otpSuccess ? 'success' : ''}`}>
          <OtpInput
            value={props.otp}
            onChange={props.handleChange}
            numInputs={6}
            shouldAutoFocus
            renderInput={(props) => <input {...props} className={(props.value + '')?.length ? 'with-value' : 'empty'} style={{}} />}
          />

        </div>
      </div>
      <div className='auth-email-form__row'>
        {
          time ?
            <div className='auth-email-form__time-help'>
              Получить новый код можно через {secondsToFormat(time)}
            </div>
            : null
        }

      </div>
      <div className='auth-email-form__row'>
        <button
          className='button-link'
          onClick={handleReset}
        >
          Авторизоваться на другую почту
        </button>
      </div>
    </div>
  </>
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      authActions: bindActionCreators(authActions, dispatch),
    };
  },
  mapStateToProps = ({applicationState, authState}) => {
    return {
      applicationState,
      authState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(OtpForm);
