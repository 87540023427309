import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as authsActions from '../actions/authActionsCreators';
import {ajax} from '../../app/tools/request';
import dashboardConstant from '../../app/constants';

const {API, ACTIONS} = constants;
const getCsrf = () => document.querySelector('meta[name="csrf-token"]').content;
const loginAjax = async (path, params, type = 'GET') => {
  const
    baseParams = {
      authenticity_token: getCsrf()
    };
  return await fetch(path, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrf(),
      'X-Requested-With': 'XMLHttpRequest'
    },
    method: type,
    body: JSON.stringify({...baseParams, ...params})
  });
}


export const loginRequest = (params = {}) => loginAjax(API.OTP_LOGIN_PATH, params, 'POST');

const authUser = async (code, email, token) => {
  const form = new FormData();

  form.append('authenticity_token', token);
  form.append('user[email]', email);
  form.append('user[password]', '1');
  form.append('user[otp_attempt]', code);

  const
    response = await fetch(API.OTP_SIGN_PATH, {
      headers: {
        'X-CSRF-Token': getCsrf(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'post',
      body: form
    });
  return response.ok;
};


export function * auth(action) {
  try {
    const response = yield call(loginRequest, {email: action.payload.email});

    const json = yield response.json();

    if (response.ok) {
      yield put(authsActions.authSuccess(json));
    } else {
      yield put(authsActions.authFailed(json));
    }
  } catch (e) {
    yield put(authsActions.authFailed(e));
  }
}

export function * otpAuth(action) {
  try {
    const response = yield call(authUser, action.payload.otp, action.payload.email, action.payload.token);

    if (response) {
      yield put(authsActions.otpAuthSuccess());
      location.reload();
    } else {
      yield put(authsActions.otpAuthFailed());
    }
  } catch (e) {
    yield put(authsActions.otpAuthFailed());
  }
}

function * authsSaga() {
  yield takeEvery(ACTIONS.AUTH, auth);
  yield takeEvery(ACTIONS.OTP_AUTH, otpAuth);
}

export default authsSaga;
