import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import VkLogo from '../../images/vk_logo.svg';
import VkQr from '../../images/vk_qr.svg';

import './style.scss';
import './mobile.scss';
import FooterBox from './FooterBox';

import * as authActions from '../../actions/authActionsCreators';

const Footer = (props) => {
  const
    {current_user, is_mobile, current_city} = props.applicationState,
    handleLogin = (e) => {
      props.authActions.setAuthOpen(true);
      e.preventDefault();
    },
    city = props.applicationState.current_city,
    citySlug = city.slug,
    branch = city.main_branch,
    vkGroupUrl = 'https://vk.com/club80451203';

  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer__wrapper'>
          <div className='footer__left'>
            <div className='footer__left-top'>
              <div className='footer__col footer__col_1'>
                <FooterBox
                  title='Покупателям'
                  useToggle={is_mobile}
                  items={[
                    <Link to=''>Доставка и оплата</Link>,
                    <Link to=''>Возврат товара</Link>,
                    <Link to={constants.ROUTES.REGIONAL_STOCKS_PATH(citySlug)}>Акции</Link>,
                    <Link to={constants.ROUTES.REGIONAL_ARTICLES_PATH(citySlug)}>Статьи</Link>,
                    current_user ?
                      <a href={constants.ROUTES.USER_DASHBOARD}>Личный кабинет</a>
                      : <button onClick={handleLogin}>Личный кабинет</button>,
                    <Link to=''>Калькуляторы</Link>
                  ]}
                />
              </div>
              <div className='footer__col footer__col_2'>
                <FooterBox
                  title='Компания'
                  useToggle={is_mobile}
                  items={[
                    <Link to={constants.ROUTES.REGIONAL_HOME_PATH(citySlug)}>О нас</Link>,
                    <Link to={constants.ROUTES.REGIONAL_EVENTS_PATH(citySlug)}>Новости</Link>,
                    <Link to={constants.ROUTES.REGIONAL_CONTACTS_PATH(citySlug)}>Наши адреса</Link>,
                    <Link to={constants.ROUTES.REVIEWS_PATH()}>Отзывы</Link>
                  ]}
                />
              </div>
              <div className='footer__col footer__col_3'>
                <FooterBox
                  title='Контакты'
                  className='no-border'
                  items={[
                    <noindex className='NoIndex_clr_bg_txt_and_img'>
                      <b>
                        {
                          city.phone ?
                            city.phone.code ? `+7 (${city.phone.code}) ${city.phone.number}` : city.phone.number
                            : null
                        }
                      </b>
                    </noindex>,
                    <span>{branch.address_short}</span>
                  ]}
                />
              </div>
            </div>
            {
              is_mobile ?
                null
                : <div className='footer__left-bottom'>
                    <p>
                      © 2016 - {new Date().getFullYear()} «ПЕРВЫЙ СТРОЙЦЕНТР САТУРН-Р» Данное предложение не является публичной офертой. Наличие материала и цены, пожалуйста, уточняйте у менеджеров. Политика конфиденциальности  Карта сайта Мы используем cookies для сбора обезличенных персональных данных. Они помогают настраивать рекламу и анализировать трафик. Оставаясь на сайте, вы соглашаетесь на сбор таких данных.
                    </p>
                  </div>
            }
          </div>
          <div className='footer__right'>
            {
              is_mobile ?
                <a href={vkGroupUrl} className='footer__vk-link' target="_blank" rel='nofollow'>
                  <img src={VkLogo} alt='Группа ВК' />
                </a>
                :
                <div className='qr-code__widget'>
                  <div className='qr-code__widget-title'>
                    Наведите камеру <br />для перехода в нашу группу
                  </div>
                  <div className='qr-code__widget-code'>
                    <img src={VkQr} alt='qr' />
                  </div>
                  <a href={vkGroupUrl} className='qr-code__widget-link' target="_blank" rel='nofollow'>
                    <img src={VkLogo} alt='Группа ВК' />
                  </a>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      authActions: bindActionCreators(authActions, dispatch),
    };
  },
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(Footer);

