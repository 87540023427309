import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import ReactOnRails from 'react-on-rails';
import {BrowserRouter, withRouter, useLocation} from 'react-router-dom';
import Routes from '../routes/routes';
import * as rubricsActions from '../actions/rubricsActionCreators';
import * as cartActions from '../actions/cartActionCreators';
import axios from 'axios';
import {useMount} from 'react-use';
import Auth from '../components/Auth';

const
  metaTag = document.querySelector('meta[name="csrf-token"]'),
  token = metaTag.content;
axios.defaults.headers.common['X-CSRF-Token'] = token;

const
  _ScrollToTop = (props) => {
    const {pathname} = useLocation();

    useEffect(() => {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    }, [pathname]);
    return props.children;
  },
  ScrollToTop = withRouter(_ScrollToTop);

const App = (props, _railsContext) => {
  const store = ReactOnRails.getStore('shopStore');
  
  return () => {
    useMount(() => {
      store.dispatch(rubricsActions.fetchRubricsTree());
      store.dispatch(cartActions.fetchCart());
    });
    return (
      <div>
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop>
              <Routes />
              <Auth />
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
      </div>
    )
  };
}

export default App;
