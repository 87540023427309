export default {
  REGIONAL_HOME_PATH: (city_slug = ':city_slug') => `/${city_slug}`,
  REGIONAL_CONTACTS_PATH: (city_slug = ':city_slug') => `/${city_slug}/contacts`,
  REGIONAL_CATALOGUE_PATH: (city_slug = ':city_slug') => `/${city_slug}/tovary`,
  REGIONAL_RUBRIC_PATH: (city_slug = ':city_slug', rubric_path = '*', params = null) => `/${city_slug}/tovary/${rubric_path}${params ? `?${params}` : ''}`,
  REGIONAL_PRODUCT_PATH: (city_slug = ':city_slug', rubric_path = '*', id = ':id') => `/${city_slug}/tovary/${rubric_path}/${id}`,

  REGIONAL_BRAND_PATH: (city_slug = ':city_slug', id = ':id') => `/${city_slug}/brands/${id}`,
  REGIONAL_BRANDS_PATH: (city_slug = ':city_slug', id = ':id') => `/${city_slug}/brands`,

  REGIONAL_STOCK_PATH: (city_slug = ':city_slug', id = ':id') => `/${city_slug}/stocks/${id}`,
  REGIONAL_STOCKS_PATH: (city_slug = ':city_slug') => `/${city_slug}/stocks`,

  REGIONAL_ARTICLE_PATH: (city_slug = ':city_slug', id = ':id') => `/${city_slug}/articles/${id}`,
  REGIONAL_ARTICLES_PATH: (city_slug = ':city_slug') => `/${city_slug}/articles`,

  REGIONAL_EVENT_PATH: (city_slug = ':city_slug', id = ':id') => `/${city_slug}/events/${id}`,
  REGIONAL_EVENTS_PATH: (city_slug = ':city_slug') => `/${city_slug}/events`,

  REVIEWS_PATH: () => `/reviews`,
  REGIONAL_PAYMENTS_PATH: (city_slug = ':city_slug') => `/${city_slug}/payments`,

  REGIONAL_CALCULATORS_PATH: (city_slug = ':city_slug', id = ":id") => `/${city_slug}/calculators/${id}`,

  HOME: '/',
  BRANDS_PATH: '/brands',
  STOCKS_PATH: '/stocks',
  EVENTS_PATH: '/events',
  ARTICLES_PATH: '/articles',
  USER_DASHBOARD: '/dashboard',
  CART: '/cart',
  ORDER_PATH: '/cart/order',

  SESSION_LOGIN: '/session/login',
  SESSION_LOGOUT: '/session/logout',

  USER: '/user',
  CHANGE_PASSWORD: '/change-password',
  PASSWORD_RECOVERY: '/password-recovery',

  SHOP_UNAVAILABLE: '/maintenance',
};
