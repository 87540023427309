import ACTIONS from '../constants/actions';

export const initialState = {
  open: false,
  otpOpen: false,
  authError: null,
  otpError: false,
  authData: {},
  otpSuccess: false
};

const authReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.SET_AUTH_OPEN]() {
      return {
        ...state,
        open: action.payload.open,
        otpSuccess: false
      };
    },
    [ACTIONS.AUTH_FAILED]() {
      return {
        ...state,
        authError: true,
        otpSuccess: false
      };
    },
    [ACTIONS.AUTH_SUCCESS]() {
      return {
        ...state,
        authData: action.payload.data,
        open: false,
        otpOpen: true,
        otpSuccess: false
      };
    },
    [ACTIONS.OTP_AUTH_FAILED]() {
      return {
        ...state,
        otpError: true,
        otpSuccess: false
      };
    },
    [ACTIONS.RESET_OTP_ERROR]() {
      return {
        ...state,
        otpError: false,
        otpSuccess: false
      };
    },
    [ACTIONS.RESET_OTP_FORM]() {
      return {
        ...state,
        authData: null,
        open: true,
        otpOpen: false,
        authError: null,
        otpError: false,
        otpSuccess: false
      };
    },
    [ACTIONS.RESET_OTP_DATA]() {
      return {
        ...state,
        authData: null,
        otpError: false,
        otpSuccess: false
      };
    },
    [ACTIONS.RESET_AUTH_ERRORS]() {
      return {
        ...state,
        authError: false,
        otpError: false,
        otpSuccess: false
      };
    },
    [ACTIONS.OTP_AUTH_SUCCESS]() {
      return {
        ...state,
        otpSuccess: true
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default authReducer;
