import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../../constants';
import CartIcon from '../../Icons/Cart';
import AccountIcon from '../../Icons/Account';
import LocationIcon from '../../Icons/Location';

import './style.scss';
import './mobile.scss';
import PhoneIcon from '../../Icons/Location';
import * as authActions from '../../../actions/authActionsCreators';

const HeaderActions = (props) => {
  const
    {current_user, is_mobile, current_city} = props.applicationState,
    {cartState} = props,
    handleLogin = (e) => {
      props.authActions.setAuthOpen(true);
      e.preventDefault();
    }
  return (
    <div className='header-actions'>
      <div className='header-actions__item'>
        <Link to={constants.ROUTES.REGIONAL_CONTACTS_PATH(current_city.slug)}>
          <span className='header-actions__item-icon'>{is_mobile ? <PhoneIcon /> : <LocationIcon />}</span>
          {is_mobile ? null : <span className='header-actions__item-title'>Адреса</span>}
        </Link>
      </div>

      <div className='header-actions__item'>
        {
          current_user ? (
            <a href={constants.ROUTES.USER_DASHBOARD}>
              <span className='header-actions__item-icon'><AccountIcon /></span>
              {is_mobile ? null : <span className='header-actions__item-title'>Профиль</span>}
            </a>

          ) : (
            <button className='js-show-sign-in-form' onClick={handleLogin}>
              <span className='header-actions__item-icon'><AccountIcon /></span>
              {is_mobile ? null : <span className='header-actions__item-title'>Войти</span>}
            </button>
          )
        }

      </div>
      <div className='header-actions__item'>
        <Link to={constants.ROUTES.CART}>
          <span className='header-actions__item-icon'>
            {
              cartState.info.qty ?
                <span className='header-actions__item-counter'>{cartState.info.qty}</span>
                : null
            }
            <CartIcon color='#fff'/>
          </span>
          {is_mobile ? null : <span className='header-actions__item-title'>Корзина</span>}
        </Link>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      authActions: bindActionCreators(authActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState, railsContext, cartState}) => {
    return {
      applicationState,
      railsContext,
      cartState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(HeaderActions);
