import React from 'react';
import {Drawer} from 'antd';
import CloseIcon from '../../Icons/Close';
import CommonWidget from '../../ProductShow/CommonWidget';
import PhoneIcon from '../../Icons/Location';
import EmailIcon from '../../Icons/Email';

const MobileAdditionalContacts = ({title, email, phone, onClose, open}) => {
  return (
    <Drawer
      className='select-drawer text-drawer'
      getContainer='body'
      height='auto'
      placement='bottom'
      onClose={onClose}
      title={title}
      closeIcon={<CloseIcon />}
      footer={null}
      open={open}
    >
      <div className='burger-menu-drawer__body'>
        <div className='product-order-widget__row product-order-widget__group no-bg'>
          {
            phone ?
              <CommonWidget
                icon={<PhoneIcon outlined />}
                value='Позвонить'
                title={phone}
              />
              : null
          }
          {
            email ?
              <CommonWidget
                icon={<EmailIcon />}
                value='Написать письмо'
                title={email}
              />
              : null
          }
        </div>
        <br/>
      </div>
    </Drawer>
  );
}

export default MobileAdditionalContacts;
