import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import PlacemarkIcon from '../ProductShow/AvailabilityDialog/placemark.svg';
import {calculateCentroid} from '../../tools/helpers';
import BranchListItem from '../BranchListItem';
import CommonWidget from '../ProductShow/CommonWidget';
import DeliveryIcon from '../Icons/Delivery';
import LocationLargeIcon from '../Icons/LocationLarge';
import StarIcon from '../Icons/Star';
import MobileAdditionalContacts from './MobileAdditionalContacts';
const CityContacts = (props) => {
  if (!props.applicationState.current_city.branches) {
    return null;
  }
  const
    {current_city: {branches}, system_settings: {additional_contacts}} = props.applicationState,
    [promotionContactsOpen, setPromotionContactsOpen] = useState(false),
    [customerContactsOpen, setCustomerContactsOpen] = useState(false),
    [otherContactsOpen, setOtherContactsOpen] = useState(false),
    [ymap, setYmap] = useState(null),
    mapRef = useRef(null),
    placemarks = branches.map(branch => ({lat: branch.lat, long: branch.long})),
    requisites = branches.find(br => br.requisites)?.requisites,
    handleMapLoad = (ymaps) => {
      setYmap(ymaps);
    };

  return (
    <>
      <section className='page-content-section'>
        <YMaps query={{lang: "ru_RU", load: "package.full"}}>
          <div className='branch-offer-availability-map'>
            <Map
              width='100%'
              onLoad={handleMapLoad}
              instanceRef={mapRef}
              defaultState={{ center: calculateCentroid(placemarks.map(pl => [pl.lat, pl.long])), zoom: 11 }}
            >
              {
                placemarks.map(pl =>
                  <Placemark
                    key={pl.lat}
                    geometry={[pl.lat, pl.long]}
                    options={{
                      iconLayout: "default#image",
                      iconImageHref: PlacemarkIcon,
                      iconImageSize: [48, 47],
                      iconImageOffset: [-24, -24]
                    }} />)
              }
            </Map>
          </div>
        </YMaps>

      </section>
      <section className='page-content-section'>
        <div className='branch-contacts-list'>
          {
            branches.map(branch => {
              return (
                <div className='branch-contacts-list__item' key={branch.id}>
                  <BranchListItem branch={branch} />
                </div>
              )
            })
          }
        </div>
      </section>
      <section className='page-content-section'>
        <div className='product-order-widget__row product-order-widget__group'>
          <CommonWidget
            icon={<DeliveryIcon outlined />}
            title='Отправить заявку на почту'
            onClick={() => {}}
          />
          {
            additional_contacts.promotion_email || additional_contacts.promotion_phone ?
              <>
                <CommonWidget
                  icon={<DeliveryIcon outlined />}
                  title='По вопросам рекламы'
                  onClick={() => setPromotionContactsOpen(true)}
                />
                <MobileAdditionalContacts
                  open={promotionContactsOpen}
                  email={additional_contacts.promotion_email}
                  phone={additional_contacts.promotion_phone}
                  onClose={() => setPromotionContactsOpen(false)}
                  title='По вопросам рекламы'
                />
              </>
              : null
          }
          {
            additional_contacts.customers_email || additional_contacts.customers_phone ?
              <>
                <CommonWidget
                  icon={<LocationLargeIcon outlined />}
                  title='Поставщикам'
                  onClick={() => setCustomerContactsOpen(true)}
                />
                <MobileAdditionalContacts
                  open={customerContactsOpen}
                  email={additional_contacts.customers_email}
                  phone={additional_contacts.customers_phone}
                  onClose={() => setCustomerContactsOpen(false)}
                  title='Поставщикам'
                />

              </>
              :null
          }
          <CommonWidget
            icon={<StarIcon outlined />}
            title='По общим вопросам'
            onClick={() => {}}
          />
        </div>
      </section>
      {
        requisites ? (
          <section className='page-content-section'>
            <p className='requisites'>{requisites}</p>
          </section>
        ) : null
      }
    </>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(CityContacts);
